import React, { useState, useEffect } from "react";
import { Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import Popup from "./Pop-up";
import axios from "axios";
import { api } from "../api";
import { TEXT_MESSAGES } from "../const";

const Home: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  const [basicInfo, setBasicInfo] = useState<any>({
    fullName: "",
    email: "",
    company_name: "",
    contact_number: "",
    secondary_phone_number:"",
    secondary_email:"",
  });
  const [contactInfo, setContactInfo] = useState<any>({
    address: "",
    city: "",
  });
  const [billingInfo, setBillingInfo] = useState<any>({
    GST_type: "",
    GST_no: "",
    currency: "",
  });

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    setUsername(storedUsername);
    const storedPopup = localStorage.getItem("pop_up");
    const isPopUpShown = storedPopup === "true";
    setShowPopUp(isPopUpShown);

    if (isPopUpShown) {
      setOpen(true);
    }

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const userData = async () => {
      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query: `
                  query GetUserById($userId: ID!) {
                    getUserById(id: $userId) {
                      basic_info {
                        customer_fullname
                        email
                        company_name
                        contact_number
                        secondary_phone_number
                        secondary_email
                      }
                      billing_info {
                        GST_type
                        GST_no
                        currency
                      }
                      contact_info {
                        address
                        city
                      }
                    }
                  }
                `,
            variables: { userId },
          },
          config
        );

        const user = response.data.data.getUserById;
        setBasicInfo({
          fullName: user.basic_info.customer_fullname,
          email: user.basic_info.email,
          company_name: user.basic_info.company_name,
          contact_number: user.basic_info.contact_number,
          secondary_phone_number:user.basic_info.secondary_phone_number,
          secondary_email:user.basic_info.secondary_email,
        });
        setBillingInfo({
          GST_type: user.billing_info.GST_type,
          GST_no: user.billing_info.GST_no,
          currency: user.billing_info.currency,
        });
        setContactInfo({
          address: user.contact_info.address,
          city: user.contact_info.city,
        });
        setLoading(false);
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (userId) {
      userData();
    }
  }, []);
  const handleUpdate = async (updateDate: {
    basicInfo: typeof basicInfo;
    contactInfo: typeof contactInfo;
    billingInfo: typeof billingInfo;
  }) => {
    const popUserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
             mutation UpdateUser(
            $id: ID!, 
            $basic_info: BasicNewInfoInput, 
            $contact_info: ContactNewInfoInput, 
            $billing_info: BillingNewInfoInput
          ) {
            updateUser(
              id: $id, 
              basic_info: $basic_info, 
              contact_info: $contact_info, 
              billing_info: $billing_info
            ) {
              success
              message
              userId
            }
          }
          `,
          variables: {
            id: popUserId,
            basic_info: {
              customer_fullname: updateDate.basicInfo.fullName,
              email: updateDate.basicInfo.email,
              company_name: updateDate.basicInfo.company_name,
              contact_number: updateDate.basicInfo.contact_number,
              secondary_phone_number:updateDate.basicInfo.secondary_phone_number,
              secondary_email:updateDate.basicInfo.secondary_email,
            },
            contact_info: {
              address: updateDate.contactInfo.address,
              city: updateDate.contactInfo.city,
            },
            billing_info: {
              GST_type: updateDate.billingInfo.GST_type,
              GST_no: updateDate.billingInfo.GST_no,
              currency: updateDate.billingInfo.currency,
            },
          },
        },
        config
      );
      const result = response.data.data.updateUser;
      if (result.success) {
        setBasicInfo(updateDate.basicInfo);
        setContactInfo(updateDate.contactInfo);
        setBillingInfo(updateDate.billingInfo);
      }
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleClosePopup = async () => {
    setOpen(false);
    localStorage.setItem("pop_up", "false");

    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
            mutation UpdateFirstLoginAt($userId: ID!) {
              updateFirstLoginAt(id: $userId) {
                firstLoginAt
              }
            }
          `,
          variables: { userId },
        },
        config
      );
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        minHeight: "85vh",
        padding: "10px 20px 10px 20px",
      }}
    >
      <Typography
        style={{
          fontWeight: 400,
          fontFamily: "Roboto",
          fontSize: isSmallScreen ? "32px" : "26px",
          color: "black",
          textAlign: "center",
        }}
      >
        {TEXT_MESSAGES.HOME_TEXT}
      </Typography>

      <Popup
        open={open}
        setOpen={setOpen}
        username={username}
        onClose={handleClosePopup}
        basicInfo={basicInfo}
        contactInfo={contactInfo}
        billingInfo={billingInfo}
        onUpdate={handleUpdate}
      />
    </div>
  );
};
export default Home;
