import React from "react";
import logo from "../../assets/logo-quotes.png";
import { pdfdata, TEXT_MESSAGES } from "../../const";
import PheonixButton from "../../Components/PheonixButton";
import { Box, Typography, Modal } from "@mui/material";
import PheonixModalProps from "./PheonixModalProps";

const PheonixModal: React.FC<PheonixModalProps> = ({
  quote,
  customerdata,
  onClose,
  handleAccept,
  rejectquote,
  open,
}) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "#05CD99";
      case "expired":
        return "#AAAAAA";
      case "rejected":
        return "#FD3232";
      case "accepted":
        return "#05CD31";
      case "pending":
        return "#7A80FF";
      default:
        return "#000000";
    }
  };
  const subtotal = quote.productOrService?.reduce(
    (acc: number, product: any) => acc + product.amount,
    0
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: 588,
          boxShadow: 24,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          padding: "5px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-20px",
            left: "-50px",
            transform: "rotate(-40deg)",
            backgroundColor: quote.status
              ? getStatusColor(quote.status)
              : "white",
            color: "#fff",
            padding: "15px 15px",
            fontSize: "18px",
            width: "200px",
            zIndex: 2,
            paddingLeft: "40px",
          }}
        >
          <span>{quote.status === "Pending" ? "Requested" : quote.status}</span>
        </div>
        <span
          className="close"
          onClick={onClose}
          style={{ alignSelf: "flex-end", cursor: "pointer", fontSize: "24px" }}
        >
          &times;
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Quotation"
            style={{
              width: "165px",
              height: "109px",
              objectFit: "cover",
              marginTop: "0px",
              marginLeft: "10px",
            }}
          />
          <div style={{ textAlign: "right" }}>
            <h2
              style={{
                textAlign: "right",
                marginRight: "20px",
                marginTop: "0px",
                fontSize: "40px",
                marginBottom: "0px",
                fontFamily: "Roboto",
              }}
            >
              {TEXT_MESSAGES.QUOTATION}
            </h2>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <table style={{ margin: "10px" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.QUOTATION_ID}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {quote.quotationid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.QUOTATION_DATE}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {quote.quotationdate}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {TEXT_MESSAGES.VALID_TILL}:
                    </td>
                    <td
                      style={{
                        color: "black",
                        padding: "5px 10px",
                        textAlign: "right",
                      }}
                    >
                      {quote.duedate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{ fontSize: "16px", fontWeight: "600", marginLeft: "15px" }}
          >
            <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {TEXT_MESSAGES.MODAL_FROM}
            </p>
            {pdfdata.PHEONIX_ADDRESS.split("\n").map((line, index) => (
              <p
                key={index}
                style={{
                  margin: "2px 0",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {line}
              </p>
            ))}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "10px",
              textAlign: "right",
            }}
          >
            <p
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: "5px 10px",
                marginBottom: "15px",
                marginRight: "22px",
              }}
            >
              {TEXT_MESSAGES.MODAL_TO}
            </p>
            {customerdata.name && (
              <>
                <p
                  style={{
                    margin: "2px 10px",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                    marginRight: "22px",
                  }}
                >
                  {customerdata.name}
                </p>
              </>
            )}

            {customerdata.address && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                  whiteSpace: "pre-line",
                }}
              >
                {customerdata.address}
              </p>
            )}

            {customerdata.email && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.email}
              </p>
            )}

            {customerdata.number && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.number}
              </p>
            )}

            {customerdata.gst_no && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.gst_no}
              </p>
            )}

            {customerdata.city && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.city}
              </p>
            )}

            {customerdata.state && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.state}
              </p>
            )}

            {customerdata.country && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.country}
              </p>
            )}

            {customerdata.pincode && (
              <p
                style={{
                  margin: "2px 10px",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "black",
                  marginRight: "22px",
                }}
              >
                {customerdata.pincode}
              </p>
            )}
          </div>
        </div>
        <table
          style={{
            width: "100%",
            marginTop: "0px",
            marginRight: "20px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRODUCT_OR_SERVICE}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PDT_TXT}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.QUAN}
              </th>
              <th
                style={{
                  borderBottom: "1px solid grey",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {TEXT_MESSAGES.PRICE}
              </th>
            </tr>
          </thead>
          <tbody>
            {quote.productOrService.map((product: any, index: any) => (
              <tr key={index} style={{ padding: "10px" }}>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.name}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.productplan}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {product.quantity}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  {quote.status === "Pending"
                    ? "Requested"
                    : `${
                        quote.currency === "Rupees"
                          ? "₹"
                          : quote.currency === "Dollars"
                          ? "$"
                          : quote.currency === "Euro"
                          ? "€"
                          : ""
                      } ${product.amount}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table style={{ width: "30%", alignSelf: "flex-end" }}>
          <tbody>
            {quote.currency === "Rupees" ? (
              customerdata.gst_no ? (
                customerdata.gst_no.startsWith("33") ? (
                  <>
                    {subtotal > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.SUBTOTAL}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          {quote.currency === "Rupees"
                            ? "₹"
                            : quote.currency === "Dollars"
                            ? "$"
                            : quote.currency === "Euro"
                            ? "€"
                            : ""}
                          {subtotal}
                        </td>
                      </tr>
                    )}
                    {quote?.taxamount !== null &&
                      quote.taxamount !== undefined &&
                      quote.taxamount > 0 && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            <Typography sx={{ marginLeft: "5%" }}>
                              {TEXT_MESSAGES.CTAX}
                            </Typography>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            {quote.currency === "Rupees"
                              ? "₹"
                              : quote.currency === "Dollars"
                              ? "$"
                              : quote.currency === "Euro"
                              ? "€"
                              : ""}
                            {(quote.taxamount / 2).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    {quote?.taxamount !== null &&
                      quote.taxamount !== undefined &&
                      quote.taxamount > 0 && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            <Typography sx={{ marginLeft: "5%" }}>
                              {TEXT_MESSAGES.STAX}
                            </Typography>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            {quote.currency === "Rupees"
                              ? "₹"
                              : quote.currency === "Dollars"
                              ? "$"
                              : quote.currency === "Euro"
                              ? "€"
                              : ""}
                            {(quote.taxamount / 2).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    {quote.discount !== undefined &&
                      quote.discount > 0 &&
                      quote.discount !== null && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            <Typography sx={{ marginLeft: "5%" }}>
                              {TEXT_MESSAGES.DISCOUNT}
                            </Typography>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            {quote.discountType === "%" ? (
                              `${quote.discount}%`
                            ) : (
                              <>
                                {quote.currency === "Rupees"
                                  ? "₹"
                                  : quote.currency === "Dollars"
                                  ? "$"
                                  : quote.currency === "Euro"
                                  ? "€"
                                  : ""}
                                {quote.discount}
                              </>
                            )}
                          </td>
                        </tr>
                      )}
                  </>
                ) : (
                  <>
                    {subtotal > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.SUBTOTAL}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          ₹{subtotal}
                        </td>
                      </tr>
                    )}
                    {quote?.taxamount !== null &&
                      quote.taxamount !== undefined &&
                      quote.taxamount > 0 && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            <Typography sx={{ marginLeft: "5%" }}>
                              {TEXT_MESSAGES.ITAX}
                            </Typography>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            {quote.currency === "Rupees"
                              ? "₹"
                              : quote.currency === "Dollars"
                              ? "$"
                              : quote.currency === "Euro"
                              ? "€"
                              : ""}
                            {quote.taxamount}
                          </td>
                        </tr>
                      )}
                    {quote.discount !== undefined &&
                      quote.discount > 0 &&
                      quote.discount !== null && (
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            <Typography sx={{ marginLeft: "5%" }}>
                              {TEXT_MESSAGES.DISCOUNT}
                            </Typography>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              lineHeight: 1,
                            }}
                          >
                            {quote.discountType === "%" ? (
                              `${quote.discount}%`
                            ) : (
                              <>
                                {quote.currency === "Rupees"
                                  ? "₹"
                                  : quote.currency === "Dollars"
                                  ? "$"
                                  : quote.currency === "Euro"
                                  ? "€"
                                  : ""}
                                {quote.discount}
                              </>
                            )}
                          </td>
                        </tr>
                      )}
                  </>
                )
              ) : (
                <>
                  {subtotal > 0 && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "5px",
                          lineHeight: 1,
                        }}
                      >
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.SUBTOTAL}
                        </Typography>
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          lineHeight: 1,
                        }}
                      >
                        {quote.currency === "Rupees"
                          ? "₹"
                          : quote.currency === "Dollars"
                          ? "$"
                          : quote.currency === "Euro"
                          ? "€"
                          : ""}
                        {subtotal}
                      </td>
                    </tr>
                  )}
                  {quote?.taxamount !== null &&
                    quote.taxamount !== undefined &&
                    quote.taxamount > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.CTAX}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          {quote.currency === "Rupees"
                            ? "₹"
                            : quote.currency === "Dollars"
                            ? "$"
                            : quote.currency === "Euro"
                            ? "€"
                            : ""}
                          {(quote.taxamount / 2).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  {quote?.taxamount !== null &&
                    quote.taxamount !== undefined &&
                    quote.taxamount > 0 && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.STAX}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          {quote.currency === "Rupees"
                            ? "₹"
                            : quote.currency === "Dollars"
                            ? "$"
                            : quote.currency === "Euro"
                            ? "€"
                            : ""}
                          {(quote.taxamount / 2).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  {quote.discount !== undefined &&
                    quote.discount > 0 &&
                    quote.discount !== null && (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          <Typography sx={{ marginLeft: "5%" }}>
                            {TEXT_MESSAGES.DISCOUNT}
                          </Typography>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "5px",
                            lineHeight: 1,
                          }}
                        >
                          {quote.discountType === "%" ? (
                            `${quote.discount}%`
                          ) : (
                            <>
                              {quote.currency === "Rupees"
                                ? "₹"
                                : quote.currency === "Dollars"
                                ? "$"
                                : quote.currency === "Euro"
                                ? "€"
                                : ""}
                              {quote.discount}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                </>
              )
            ) : (
              <>
                {subtotal > 0 && (
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "right",
                        padding: "5px",
                        lineHeight: 1,
                      }}
                    >
                      <Typography sx={{ marginLeft: "5%" }}>
                        {TEXT_MESSAGES.SUBTOTAL}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        padding: "5px",
                        lineHeight: 1,
                      }}
                    >
                      {quote.currency === "Rupees"
                        ? "₹"
                        : quote.currency === "Dollars"
                        ? "$"
                        : quote.currency === "Euro"
                        ? "€"
                        : ""}
                      {subtotal}
                    </td>
                  </tr>
                )}
                {quote.discount !== undefined &&
                  quote.discount > 0 &&
                  quote.discount !== null && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "5px",
                          lineHeight: 1,
                        }}
                      >
                        <Typography sx={{ marginLeft: "5%" }}>
                          {TEXT_MESSAGES.DISCOUNT}
                        </Typography>
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          lineHeight: 1,
                        }}
                      >
                        {quote.discountType === "%" ? (
                          `${quote.discount}%`
                        ) : (
                          <>
                            {quote.currency === "Rupees"
                              ? "₹"
                              : quote.currency === "Dollars"
                              ? "$"
                              : quote.currency === "Euro"
                              ? "€"
                              : ""}
                            {quote.discount}
                          </>
                        )}
                      </td>
                    </tr>
                  )}
              </>
            )}

            {Number(quote.price) > 0 && (
              <tr>
                <td></td>
                <td></td>
                <td
                  style={{
                    textAlign: "right",
                    padding: "5px",
                    lineHeight: 1,
                  }}
                >
                  <Typography sx={{ marginLeft: "5%" }}>
                    {TEXT_MESSAGES.TOTAL}
                  </Typography>
                </td>
                <td
                  style={{ textAlign: "left", padding: "5px", lineHeight: 1 }}
                >
                  {quote.currency === "Rupees"
                    ? "₹"
                    : quote.currency === "Dollars"
                    ? "$"
                    : quote.currency === "Euro"
                    ? "€"
                    : ""}
                  {quote.price}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table>
          <tbody>
            {quote.quotationnotes && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{TEXT_MESSAGES.QUOTE_NOTES}</Typography>
                  <Typography>{quote.quotationnotes}</Typography>
                </div>
              </>
            )}
            <tr></tr>
            {quote.customernotes && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{TEXT_MESSAGES.CUSTOM_NOTES}</Typography>
                  <Typography>{quote.customernotes}</Typography>
                </div>
              </>
            )}
            <tr></tr>
            {quote.terms_conditions && (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{TEXT_MESSAGES.QUOTE_TERM}</Typography>
                  <Typography>{quote.terms_conditions}</Typography>
                </div>
              </>
            )}
            <tr></tr>
          </tbody>
        </table>
        {quote.status?.toLowerCase() === "active" && (
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              position: "relative",
              justifyContent: "flex-end",
            }}
          >
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#1A504C",
              }}
              onClick={() => handleAccept(quote)}
              label={TEXT_MESSAGES.ACCEPT}
            ></PheonixButton>
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#D32F2F",
              }}
              onClick={() => rejectquote(quote)}
              label={TEXT_MESSAGES.REJECT}
            ></PheonixButton>
          </div>
        )}
      </Box>
    </Modal>
  );
};
export default PheonixModal;
