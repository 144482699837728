import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { PheonixBox } from "pheonixui";
import { PheonixTextfield } from "pheonixui";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import WavingHandOutlinedIcon from "@mui/icons-material/WavingHandOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import productIntro from "../assets/productIntro.mp4";
import PheonixTextFields from "../Components/PheonixTextField";

interface PopupProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  username: string | null;
  onClose: () => void;
  basicInfo: {
    fullName: string;
    email: string;
    company_name: string;
    contact_number: string;
    secondary_phone_number: string;
    secondary_email: string;
  };
  billingInfo: {
    GST_type: string;
    GST_no: string;
    currency: string;
  };
  contactInfo: {
    address: string;
    city: string;
  };
  onUpdate: (updatedData: {
    basicInfo: PopupProps["basicInfo"];
    contactInfo: PopupProps["contactInfo"];
    billingInfo: PopupProps["billingInfo"];
  }) => void;
}
const Popup: React.FC<PopupProps> = ({
  open,
  setOpen,
  username,
  onClose,
  basicInfo,
  billingInfo,
  contactInfo,
  onUpdate,
}) => {
  const [step, setStep] = useState<number>(1);
  const [updatedInfo, setUpdatedInfo] = useState({
    basicInfo: { ...basicInfo },
    contactInfo: { ...contactInfo },
    billingInfo: { ...billingInfo },
  });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    setUpdatedInfo({
      basicInfo: { ...basicInfo },
      contactInfo: { ...contactInfo },
      billingInfo: { ...billingInfo },
    });
  }, [basicInfo, contactInfo, billingInfo]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleNext = async () => {
    const fieldsToValidate =
      step === 1
        ? ["Full Name", "E-mail", "Phone Number"]
        : step === 2
        ? ["Secondary Email-ID", "Secondary Phone Number"]
        : ["GST Number"];

    fieldsToValidate.forEach((field) => validateField(field));

    const hasErrors = fieldsToValidate.some((field) => fieldErrors[field]);

    if (hasErrors) {
      console.error(
        "Validation errors occurred. Fix errors before proceeding."
      );
      return;
    }
    setFieldErrors({});
    if (step < 3) {
      setStep(step + 1);
      await onUpdate({ ...updatedInfo });
    } else {
      try {
        await onUpdate({ ...updatedInfo });
        localStorage.setItem("pop_up", "false");
        handleClose();
      } catch (error) {
        console.error(ERROR_MESSAGES, error);
      }
    }
  };
  const handleBack = () => {
    setFieldErrors({});
    step > 1 && setStep(step - 1);
  };
  const handleSkip = () => {
    handleClose();
    setFieldErrors({});
  };

  const gstOptions = [
    { value: "Registered", label: "Registered" },
    { value: "Unregistered", label: "Unregistered" },
  ];
  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "INR", label: "INR" },
    { value: "EUR", label: "EUR" },
  ];

  const [currency, setCurrency] = useState<string | null>(null);
  const [gst, setGST] = useState<string | null>(null);

  const handleCurrencyChange = (event: any, newValue: any) => {
    const currencyValue = newValue ? newValue.value : null;
    setCurrency(currencyValue);
    setUpdatedInfo((prev) => ({
      ...prev,
      billingInfo: { ...prev.billingInfo, currency: currencyValue },
    }));
  };

  const validateField = (fieldLabel: string) => {
    const fieldValue = {
      "Full Name": updatedInfo.basicInfo.fullName,
      "E-mail": updatedInfo.basicInfo.email,
      "Phone Number": updatedInfo.basicInfo.contact_number,
      "Secondary Phone Number": updatedInfo.basicInfo.secondary_phone_number,
      "Secondary Email-ID": updatedInfo.basicInfo.secondary_email,
      "GST Number": updatedInfo.billingInfo.GST_no,
    }[fieldLabel];
    let error = "";
    switch (fieldLabel) {
      case "Secondary Email-ID":
      case "E-mail":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_REQ,
          }));
        } else if (!TEXT_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

      case "Full Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_REQ,
          }));
        } else if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]*$/.test(fieldValue || "")) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NAME_VALID,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;

        case "GST Number":
          if (!fieldValue?.trim()) {
            error = ERROR_MESSAGES.GST_NO_IS_REQUIRED; 
          } else if (!/^[a-zA-Z0-9]+$/.test(fieldValue)) {
            error = ERROR_MESSAGES.GST_NO_SHOULD;
          } else if (fieldValue.length !== 15) {
            error = ERROR_MESSAGES.GST_NO_SHOULD_1;
          } else {
            error = "";
          }
          break;

      case "Phone Number":
        if (!fieldValue?.trim()) {
          error = ERROR_MESSAGES.NUMBER_MSG;
        } else if (!/^\d{10}$/.test(fieldValue.trim())) {
          error = ERROR_MESSAGES.INVALID_PHONE;
        }
        break;
      default:
        break;
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldLabel]: error,
    }));
  };
  const handleGSTChange = (
    event: any,
    newValue: { value: string; label: string } | null
  ) => {
    const gstValue = newValue ? newValue.value : "";
    setGST(gstValue);

    setUpdatedInfo((prev) => ({
      ...prev,
      billingInfo: { ...prev.billingInfo, GST_type: gstValue },
    }));
  };

  const popUpSize = (): { width: string; height: string } => {
    switch (step) {
      case 1:
      case 2:
        return { width: "650px", height: "525px" };
      case 3:
        return { width: "650px", height: "328px" };
      default:
        return { width: "650px", height: "525px" };
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <div>
            <PheonixBox
              sx={{ width: "573px", height: "35px", position: "relative" }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  width: "103px",
                  height: "19px",
                  position: "absolute",
                  left: "42px",
                }}
              >
                {TEXT_MESSAGES.PRODUCT_INTRO}
              </Typography>
            </PheonixBox>
            <PheonixBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 10,
                width: "549px",
                height: "282px",
                paddingBottom: "24px",
              }}
            >
              <video
                width="419px"
                height="auto"
                controls
                style={{
                  borderRadius: "16px",
                  border: "1px solid",
                  borderColor: "#E4E4E4",
                }}
              >
                <source src={productIntro} type="video/mp4" />
              </video>
            </PheonixBox>
          </div>
        );
      case 2:
        return (
          <PheonixBox
            sx={{
              width: "565px",
              height: "267px",
              borderRadius: "16px",
              gap: "24px",
              display: "flex",
              flexDirection: "row",
              marginTop: "4px",
            }}
          >
            <PheonixBox
              sx={{
                display: "flex",
                gap: "35px",
                width: "250px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <PheonixTextfield
                type="text"
                label="Company Name"
                value={updatedInfo.basicInfo.company_name || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      company_name: e.target.value,
                    },
                  }))
                }
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="Full Name"
                value={updatedInfo.basicInfo.fullName || ""}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      fullName: trimmedValue === "" ? "" : e.target.value,
                    },
                  }));
                }}
                onBlur={() =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      fullName: prev.basicInfo.fullName.trim(),
                    },
                  }))
                }
                error={Boolean(fieldErrors["Full Name"])}
                helperText={fieldErrors["Full Name"]}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="Address"
                value={updatedInfo.contactInfo.address || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    contactInfo: {
                      ...prev.contactInfo,
                      address: e.target.value,
                    },
                  }))
                }
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="City"
                value={updatedInfo.contactInfo.city || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    contactInfo: { ...prev.contactInfo, city: e.target.value },
                  }))
                }
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
            </PheonixBox>
            <PheonixBox
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "35px",
                width: "250px",
              }}
            >
              <PheonixTextfield
                type="text"
                label="Phone Number"
                value={updatedInfo.basicInfo.contact_number || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      contact_number: e.target.value,
                    },
                  }))
                }
                onBlur={() => validateField("Phone Number")}
                error={Boolean(fieldErrors["Phone Number"])}
                helperText={fieldErrors["Phone Number"]}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="E-mail"
                value={updatedInfo.basicInfo.email || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: { ...prev.basicInfo, email: e.target.value },
                  }))
                }
                onBlur={() => validateField("E-mail")}
                error={Boolean(fieldErrors["E-mail"])}
                helperText={fieldErrors["E-mail"]}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="Secondary Phone Number"
                value={updatedInfo.basicInfo.secondary_phone_number || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      secondary_phone_number: e.target.value,
                    },
                  }))
                }
                onBlur={() => validateField("Secondary Phone Number")}
                error={Boolean(fieldErrors["Secondary Phone Number"])}
                helperText={fieldErrors["Secondary Phone Number"]}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
              <PheonixTextfield
                type="text"
                label="Secondary Email-ID"
                value={updatedInfo.basicInfo.secondary_email || ""}
                onChange={(e) =>
                  setUpdatedInfo((prev) => ({
                    ...prev,
                    basicInfo: {
                      ...prev.basicInfo,
                      secondary_email: e.target.value,
                    },
                  }))
                }
                onBlur={() => validateField("Secondary Email-ID")}
                error={Boolean(fieldErrors["Secondary Email-ID"])}
                helperText={fieldErrors["Secondary Email-ID"]}
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "250px",
                  height: "40px",
                }}
              />
            </PheonixBox>
          </PheonixBox>
        );

      case 3:
        return (
          <div>
            <PheonixBox
              sx={{
                gap: "34px",
                width: "565px",
                height: "112px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <PheonixBox
                sx={{
                  display: "flex",
                  gap: "32px",
                  width: "532px",
                  height: "40px",
                  flexDirection: "row",
                  marginTop: "28px",
                }}
              >
                <Autocomplete
                  options={gstOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    gstOptions.find(
                      (option) =>
                        option.value === updatedInfo.billingInfo.GST_type
                    ) || null
                  }
                  onChange={handleGSTChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="GST Type"
                      placeholder="Select GST Type"
                      variant="outlined"
                    />
                  )}
                  sx={{ width: "250px", marginTop: "18px", color: "#00000061" }}
                  fullWidth
                />
                <PheonixTextfield
                  type="text"
                  label="GST Number"
                  value={updatedInfo.billingInfo.GST_no || ""}
                  onChange={(e) =>
                    setUpdatedInfo((prev) => ({
                      ...prev,
                      billingInfo: {
                        ...prev.billingInfo,
                        GST_no: e.target.value,
                      },
                    }))
                  }
                  onBlur={() => validateField("GST Number")}
                  error={Boolean(fieldErrors["GST Number"])}
                  helperText={fieldErrors["GST Number"]}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400px",
                    width: "250px",
                    height: "40px",
                  }}
                />
              </PheonixBox>

              <PheonixBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  options={currencyOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    currencyOptions.find(
                      (option) =>
                        option.value === updatedInfo.billingInfo.currency
                    ) || null
                  }
                  onChange={handleCurrencyChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency"
                      placeholder="Select Currency"
                      variant="outlined"
                    />
                  )}
                  sx={{ width: "250px", gap: "48px", color: "#00000061" }}
                  fullWidth
                />
              </PheonixBox>
            </PheonixBox>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          ...popUpSize(),
          borderRadius: "16px",
          padding: "16px",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          width: "573px",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <PheonixBox
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            width: "100%",
            gap: "8px",
            justifyContent: "flex-start",
          }}
        >
          <WavingHandOutlinedIcon
            sx={{
              color: "#651CB5",
              width: "40px",
              height: "40px",
            }}
          />
          <PheonixBox
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              marginLeft: "2px",
              width: "424px",
              height: "60px",
            }}
          >
            {username ? (
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "700px",
                  fontSize: "24px",
                  height: "32px",
                  width: "318px",
                }}
              >
                Hi! {username}
              </Typography>
            ) : null}
            <Typography
              color="#737373"
              sx={{
                fontWeight: "500px",
                fontSize: "16px",
                width: "424px",
                height: "20px",
              }}
            >
              {step === 1 && `${TEXT_MESSAGES.STEP_1}`}
              {step === 2 && `${TEXT_MESSAGES.STEP_2}`}
              {step === 3 && `${TEXT_MESSAGES.STEP_3}`}
            </Typography>
          </PheonixBox>
        </PheonixBox>
        <IconButton
          onClick={handleClose}
          sx={{ width: "32px", height: "32px", color: "#101828" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ overflow: "hidden" }}>
        {getStepContent(step)}
      </DialogContent>

      <DialogActions
        sx={{
          width: "573px",
          height: "44px",
          paddingY: "8px 8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <PheonixBox
          sx={{
            width: "114px",
            height: "28px",
            gap: "8px",
          }}
        >
          {step === 1 ? (
            <Button
              onClick={handleSkip}
              sx={{
                borderRadius: "16px",
                width: "63px",
                height: "28px",
                fontWeight: "600px",
                fontSize: "14px",
                border: "1px solid",
                color: "#060606",
                paddingY: "4px",
                paddingX: "16px",
                gap: "10px",
                borderColor: "#7A80FF",
                backgroundColor: "#ECEEFF",
                transform: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#ECEEFF",
                },
                "&:active": {
                  backgroundColor: "#6200ea",
                  color: "#FFFFFF",
                },
              }}
            >
              {TEXT_MESSAGES.SKIP_BUTTON}
            </Button>
          ) : (
            <PheonixBox
              sx={{
                width: "114px",
                height: "28px",
                gap: "8px",
                display: "flex",
              }}
            >
              <Button
                onClick={handleSkip}
                sx={{
                  width: "51px",
                  height: "28px",
                  fontWeight: "400px",
                  fontSize: "12px",
                  marginLeft: "60px",
                  border: "1px solid",
                  paddingY: "4px 4px",
                  paddingX: "16px 16px",
                  color: "#060606",
                  gap: "4px",
                  backgroundColor: "#ECEEFF",
                  borderRadius: "16px",
                  borderColor: "#7A80FF",
                  transform: "none",
                  textTransform: "none",
                  "&:active": {
                    backgroundColor: "#6200ea",
                    color: "#FFFFFF",
                  },
                }}
              >
                {TEXT_MESSAGES.SKIP_BUTTON}
                <ArrowForwardIosIcon
                  sx={{ width: "10px", height: "10px", color: "#7A80FF" }}
                />
              </Button>
              <Button
                onClick={handleBack}
                sx={{
                  width: "55px",
                  height: "28px",
                  fontWeight: "400px",
                  fontSize: "12px",
                  border: "1px solid",
                  paddingY: "4px 4px",
                  paddingX: "8px 8px",
                  color: "#060606",
                  gap: "4px",
                  backgroundColor: "#ECEEFF",
                  borderRadius: "16px",
                  borderColor: "#7A80FF",
                  transform: "none",
                  textTransform: "none",
                  "&:active": {
                    backgroundColor: "#6200ea",
                    color: "#FFFFFF",
                  },
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{ width: "10px", height: "10px", color: "#7A80FF" }}
                />
                {TEXT_MESSAGES.BACK_BUTTON}
              </Button>
            </PheonixBox>
          )}
        </PheonixBox>
        <PheonixBox
          sx={{
            width: "99px",
            height: "15px",
            display: "flex",
            gap: "8px",
            right: "40%",
            paddingY: "8px 8px",
          }}
        >
          {[1, 2, 3, 4].map((s) => (
            <CircleIcon
              key={s}
              fontSize="small"
              sx={{
                width: s === step ? "30px" : "15px",
                height: "15px",
                fill: s === step ? "#7A80FF" : "#E6E6FF",
                borderRadius: s === step ? "15px" : "15px",
                backgroundColor: s === step ? "#7A80FF" : "#E6E6FF",
                transition: "all 0.3s ease",
                gap: 8,
              }}
            />
          ))}
        </PheonixBox>

        <Button
          onClick={handleNext}
          sx={{
            fontWeight: "600",
            fontSize: "12px",
            color: "#FFFFFF",
            display: "flex",
            backgroundColor: "#651CB5",
            borderRadius: "16px",
            height: "28px",
            width: step === 1 ? "65px" : "121px",
            transform: "none",
            textTransform: "none",
            transition: "width 0.3s ease",
            "&:hover": {
              backgroundColor: "#651CB5",
            },
          }}
        >
          {step < 2 ? `${TEXT_MESSAGES.NEXT}` : `${TEXT_MESSAGES.YES_CONFIRM}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
