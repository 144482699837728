import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeOutlinedIcon from "@mui/icons-material/HomeRounded";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ListAltOutlinedIcon from "@mui/icons-material/ListAltRounded";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryRounded";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteRounded";
import AnalyticsOutlinedIcon from "@mui/icons-material/Analytics";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptRounded";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentRounded";
import { useTheme } from "@mui/material/styles";
import logo1 from "../assets/PWLOGO2.png";
import SidebarProps from "../interfaces/SidebarpRops";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import  PheonixSideBar  from "../Components/PheonixSidebarUI";

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string>("/home");
  const navigate = useNavigate(); 

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/quotation")) {
      setSelectedItem("/quotation");
    } else if (location.pathname.startsWith("/invoice")) {
      setSelectedItem("/invoice");
    } else if (location.pathname.startsWith("/orders")) {
      setSelectedItem("/orders");
    } else if (location.pathname.startsWith("/payments")) {
      setSelectedItem("/payments");
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      onToggle();
    }
  };
  const handleSelect = (to: string) => {
    setSelectedItem(to);
    navigate(to); 
  };

  const iconComponent = isOpen ? (
    <ChevronLeftIcon style={{ width: "28px", height: "28px" }} />
  ) : (
    <ChevronRightIcon style={{ width: "28px", height: "28px" }} />
  );
  let menuItems;
  if (loggedInUser === "1") {
    menuItems = [
      {
        icon:
            <HomeOutlinedIcon />,
        text: "Home",
        to: "/home",
      },
      {
        icon: <AccountCircleOutlinedIcon />,
        text: "Customers",
        to: "/customer",
      },
      {
        icon:
            <CategoryOutlinedIcon />,
        text: "Solutions",
        to: "/products",
      },
      {
        icon:
          <RequestQuoteOutlinedIcon /> ,
        text: "Quotations",
        to: "/quotation",
      },
      {
        icon: 
          <ReceiptOutlinedIcon /> ,
        text: "Invoices",
        to: "/invoice",
      },
      {
        icon: 
          <ListAltOutlinedIcon />,
        text: "Orders",
        to: "/orders",
      },
      {
        icon: 
          <PaymentOutlinedIcon />,
        text: "Payment",
        to: "/payments",
      },
      {
        icon:
          <AnalyticsOutlinedIcon />,
        text: "Reports",
        to: "/report",
      },
    ];
  } else {
    menuItems = [
      {
        icon:
          <HomeOutlinedIcon />,
        text: "Home",
        to: "/dashboard",
      },

      {
        icon: 
          <CategoryOutlinedIcon />,
        text: "Solutions",
        to: "/products",
      },
      {
        icon:
            <RequestQuoteOutlinedIcon />,
        text: "Quotations",
        to: "/quotation",
      },
      {
        icon:
          <ReceiptOutlinedIcon />,
        text: "Invoices",
        to: "/invoice",
      },
      {
        icon:
          <ListAltOutlinedIcon />,
        text: "Orders",
        to: "/orders",
      },
      {
        icon: 
          <PaymentOutlinedIcon />,
        text: "Payment",
        to: "/payments",
      },
      {
        icon: 
          <AnalyticsOutlinedIcon />,
        text: "Reports",
        to: "/report",
      },
    ];
  }

  useEffect(() => {
    if (isMobile && isOpen) {
      const menuIconElement = document.getElementById("menuIcon");
      if (menuIconElement) {
        setMenuAnchorEl(menuIconElement);
      }
    }
  }, [isMobile, isOpen]);
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: "70px",
        flexShrink: 0,
        border: "none",
        "& .MuiDrawer-paper": {
          background: "#F0F0F0",
          fontSize: "16px",
          color: "white",
          width: "70px",
          boxSizing: "border-box",
          transition: "width 0.2s ease-in-out, visibility 0.2s linear",
          overflow: "hidden",
          border: "none",
        },
        '.css-1lwhjos-MuiPaper-root-MuiDrawer-paper':{
          borderRight:"0px solid rgba(0, 0, 0, 0.12)"
        }
      }}
      open={isOpen}
      onClose={onToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div
        onClick={handleMenuClick}
        style={{
          position: "absolute",
          top: "27px",
          left: "25px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo1}
            alt="Logo1"
            style={{ height: "50px", width: "50px", marginLeft: "-10px", marginTop:"-22px" }}
          />
        </div>
      </div>
      <List
        sx={{
          top: "60px",
          width:"100%", 
        }}
      >
        {menuItems.map((item, index) => (
          <PheonixSideBar
            key={index}
            icon={item.icon}
            text={item.text}
            to={item.to}
            selected={
              (location.pathname.startsWith("/quotation") && item.text === "Quotations") ||
              (location.pathname.startsWith("/invoice") && item.text === "Invoices") ||
              (location.pathname.startsWith("/orders") && item.text === "Orders") || 
              (location.pathname.startsWith("/payments") && item.text === "Payment") || 
              location.pathname === item.to
            }
            onSelect={() => handleSelect(item.to)}
            boxSx={{backgroundColor: "#F0F0F0"}}
            listItemSx={{
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                height: "auto",
                width: "6px",
                backgroundColor:
                  selectedItem === item.to ? "#181D8C" : "transparent",
              },
              backgroundColor:
                selectedItem === item.to ? "white" : "transparent",
              "&:hover": {
                color: "white",
                backgroundColor: "white",
                width: "2%",
                cursor:"pointer"
              },
            }}
            listIconSx={{
              height: "10%",
              color: "#3F439E",
              
              "&:hover": {
                color: "#3F439E",
                height: "10%",
                cursor:"pointer",
              },
            }}
        
            listTextSx={{
              color: "#3F439E",
              fontSize: "11px !important",
              fontWeight: "500",
              '.MuiTypography-root:where(.css-rutsqn .MuiListItemText-primary)':{
                fontSize: "11px !important",
                fontWeight: "500",
              },
              '.MuiTypography-root:where(.css-1356jdj .MuiListItemText-primary)':{
                fontSize: "11px !important",
                fontWeight: "500",
              },
              ".css-rizt0-MuiTypography-root": {
                fontSize: "11px !important",
                fontWeight: "500",
              },
              '.css-fyswvn':{
                fontSize: "11px !important",
                fontWeight: "500",
              },
             ' .css-10hburv-MuiTypography-root': {
              fontSize: "11px !important",
              fontWeight: "500",
             },
             '.css-yb0lig':{
              fontSize: "11px !important",
                fontWeight: "500",
             }
            }}
          />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
