import React, { useState, useEffect } from "react";
import { api } from "../api";
import axios from "axios";
import Order from "../Container/Order/Order";
import PheonixPaper from "../Components/PheonixPaper";
import { useTheme } from "@mui/material/styles";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PheonixChip from "../Components/PheonixChip";
import PheonixButton from "../Components/PheonixButton";
import Popover from "@mui/material/Popover";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { Typography, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PheonixCustomerModal from "../Components/PheonixCustomerModal";
import DeleteModal from "../Components/PheonixDeleteModal";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_CUSTOMER_QUERY } from "../graphql/query";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_CUSTOMER_MUTATION,
  EDIT_CUSTOMER_MUTATION,
  DELETE_CUSTOMER,
  EXITED_CUSTOMER_ADDED_MUTATION,
  UPDATE_STATUS,
} from "../graphql/mutation";
import more from "../assets/Vector.png";
import CustomerProps from "../interfaces/customerPRops";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import { config } from "dotenv";
interface StatusSwitchProps {
  id: string;
  status: boolean;
}

interface CustomerData {
  "Company Name": string;
  "Contact Number": string;
  "Contact Email ID": string;
  "Customer Full Name": string;
  "GST Number": string;
  Address: string;
  City: string;
  Pincode: string;
  State: string;
  Country: string;
}
interface Field {
  label: keyof CustomerData;
  type: string;
}
const initialCustomerData: CustomerData = {
  "Company Name": "",
  "Contact Number": "",
  "Contact Email ID": "",
  "Customer Full Name": "",
  "GST Number": "",
  Address: "",
  City: "",
  Pincode: "",
  State: "",
  Country: "",
};
const Customer: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [ordersData, setOrdersData] = useState<CustomerProps[]>([]);
  const [switchSelected, setSwitchSelected] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const [filteredOrdersData, setFilteredOrdersData] = useState<CustomerProps[]>(
    []
  );
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [viewMode, setIsViewMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [customerData, setCustomerData] =
    useState<CustomerData>(initialCustomerData);
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [editCustomerId, setEditCustomerId] = useState("");
  const [exitedCustomer] = useMutation(EXITED_CUSTOMER_ADDED_MUTATION);
  const [exitModalOpen, setexitModalOpen] = useState(false);
  const fetchCustomerData = async () => {
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_CUSTOMER_QUERY,
          variables: {
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
            searchQuery: searchQuery,
          },
        },
        config
      );
      const customers = response.data.data.getAddedUsers.users;
      const flattenData = (data: any) => {
        return data.map((item: any) => ({
          id: item._id,
          firstname: item.basic_info.firstname,
          company_name: item.basic_info.company_name,
          gst_no: item.billing_info.GST_no,
          email: item.basic_info.email,
          phone_number: item.basic_info.phone_number,
          address: item.basic_info.shipping_address.address_line1,
          city: item.basic_info.shipping_address.city,
          pincode: item.basic_info.shipping_address.pincode,
          state: item.basic_info.shipping_address.state,
          country: item.basic_info.shipping_address.country,
          active_status: item.active_status,
          ...item,
        }));
      };

      const responseDataWithIndex = flattenData(customers);
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const ListWithSerialNo = responseDataWithIndex.map(
        (responseDataWithIndex: CustomerData, index: number) => ({
          ...responseDataWithIndex,
          serial_no: (startIndex + index).toString(),
        })
      );

      setCustomerList(ListWithSerialNo);
      setTotalPages(response.data.data.getAddedUsers.totalCount);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    setIsViewMode(false);
  }, [paginationModel.page, paginationModel.pageSize, searchQuery]);

  const [succMessage, setSuccMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
  };

  const handleViewClick = (id: string) => {
    const editCustomer = customerList.find((obj) => obj.id === id);
    if (editCustomer) {
      setEditCustomerId(editCustomer.id);
      setCustomerData({
        "Company Name": editCustomer.company_name,
        "Contact Number": editCustomer.phone_number,
        "Contact Email ID": editCustomer.email,
        "Customer Full Name": editCustomer.firstname,
        "GST Number": editCustomer.gst_no,
        Address: editCustomer.address,
        City: editCustomer.city,
        Pincode: editCustomer.pincode,
        State: editCustomer.state,
        Country: editCustomer.country,
      });
      setOpen(true);
      setIsViewMode(true);
      setSwitchSelected(false);
    }
  };

  const handleDeleteClick = (id: string, name: string) => {
    setEntityToDelete(id);
    setEntityToDisplay(name);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    const customer = customerList.find(
      (customer) => customer.id === entityToDelete
    );
    const token = localStorage.getItem("token");
    const query = DELETE_CUSTOMER;
    const variables = {
      id: entityToDelete,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { success, message } = response.data.data.deleteUserById;
      if (success) {
        setSnackbarSeverity("error");
        setSuccMessage(
          `${customer?.customer_no} - ${TEXT_MESSAGES.CUSTOMER_DELETE_SUCCESS}`
        );
        setDeleteModalOpen(false);
      } else {
        setSnackbarSeverity("error");
        setSuccMessage(TEXT_MESSAGES.CUSTOMER_DELETE_FAIL);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        fetchCustomerData();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (viewMode) {
      const query = EDIT_CUSTOMER_MUTATION;
      const variables = {
        _id: editCustomerId,
        user_id: userId,
        company_name: customerData["Company Name"],
        phone_number: customerData["Contact Number"],
        email: customerData["Contact Email ID"],
        firstname: customerData["Customer Full Name"],
        GST_no: customerData["GST Number"],
        address_line1: customerData["Address"],
        mail_status: switchSelected,
        city: customerData["City"],
        pincode: customerData["Pincode"],
        state: customerData["State"],
        country: customerData["Country"],
        passwordCheck: switchSelected,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query,
            variables,
          },
          config
        );

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.editUser
        ) {
          const { success, message, data } = response.data.data.editUser;
          if (data && data._id) {
            setSnackbarSeverity("success");
            setSuccMessage(
              `${data.customer_no} - ${TEXT_MESSAGES.CUSTOMER_EDIT_SUCCESS}`
            );
            setOpen(false);
          } else {
            setSnackbarSeverity("error");
            setSuccMessage(message);
          }
        } else {
          setSnackbarSeverity("error");
          setSuccMessage(TEXT_MESSAGES.Customer_FAILURE);
        }
        setSnackbarOpen(true);

        setTimeout(() => {
          setSnackbarOpen(false);
          fetchCustomerData();
          setCustomerData({
            "Company Name": "",
            "Contact Number": "",
            "Contact Email ID": "",
            "Customer Full Name": "",
            "GST Number": "",
            Address: "",
            City: "",
            Pincode: "",
            State: "",
            Country: "",
          });
          setIsViewMode(false);
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    } else {
      const query = ADD_CUSTOMER_MUTATION;
      const variables = {
        user_id: userId,
        company_name: customerData["Company Name"],
        phone_number: customerData["Contact Number"],
        email: customerData["Contact Email ID"],
        firstname: customerData["Customer Full Name"],
        GST_no: customerData["GST Number"],
        address_line1: customerData["Address"],
        mail_status: switchSelected,
        admin_status: userSelected,
        city: customerData["City"],
        pincode: customerData["Pincode"],
        state: customerData["State"],
        country: customerData["Country"],
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query,
            variables,
          },
          config
        );

        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.addUser
        ) {
          const { success, message, data } = response.data.data.addUser;
          if (data && data._id) {
            setSnackbarSeverity("success");
            setSuccMessage(
              `${data.customer_no} - ${TEXT_MESSAGES.CUSTOMER_SUCCESS}`
            );
            setOpen(false);
          } else if (message === "A deleted account is still showing") {
            setexitModalOpen(true);
            return;
          } else {
            setSnackbarSeverity("error");
            setSuccMessage(message);
          }
        }

        setSnackbarOpen(true);

        setTimeout(() => {
          setSnackbarOpen(false);
          setCustomerData({
            "Company Name": "",
            "Contact Number": "",
            "Contact Email ID": "",
            "Customer Full Name": "",
            "GST Number": "",
            Address: "",
            City: "",
            Pincode: "",
            State: "",
            Country: "",
          });
          fetchCustomerData();
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const ExitConfirm = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await exitedCustomer({
        variables: {
          user_id: userId,
          _id: editCustomerId,
          company_name: customerData["Company Name"],
          phone_number: customerData["Contact Number"],
          email: customerData["Contact Email ID"],
          firstname: customerData["Customer Full Name"],
          GST_no: customerData["GST Number"],
          address_line1: customerData["Address"],
          city: customerData["City"],
          pincode: customerData["Pincode"],
          state: customerData["State"],
          country: customerData["Country"],
        },
      });
      const { success, message, data } = response.data.existingUserAdded;

      if (success) {
        setOpen(false);
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSuccMessage(ERROR_MESSAGES.CUSTOMER_REACTIVE);

        setTimeout(() => {
          setSnackbarOpen(false);
          setCustomerData({
            "Company Name": "",
            "Contact Number": "",
            "Contact Email ID": "",
            "Customer Full Name": "",
            "GST Number": "",
            Address: "",
            City: "",
            Pincode: "",
            State: "",
            Country: "",
          });
          fetchCustomerData();
        }, 3000);
        setexitModalOpen(false);
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSuccMessage(ERROR_MESSAGES.CUSTOMER_DATA);
      setSnackbarOpen(true);
      setexitModalOpen(false);
    }
  };
  const StatusSwitch: React.FC<StatusSwitchProps> = ({ id, status }) => {
    const [currentStatus, setCurrentStatus] = useState<boolean>(status);
    const [succMessage, setSuccMessage] = useState<string>("");

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newStatus = event.target.checked;
      setCurrentStatus(newStatus);

      const token = localStorage.getItem("token");

      const query = UPDATE_STATUS;
      const variables = {
        id: id,
        active_status: newStatus,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query,
            variables,
          },
          config
        );

        const { success, message } = response.data.data.updateStatus;

        if (success) {
          fetchCustomerData();
        } else {
          setSuccMessage(message || "Status update failed");
          setSnackbarSeverity("error");
        }
      } catch (error) {
        console.error("Failed to update status:", error);
        setCurrentStatus(!newStatus);
      }
    };
    return (
      <>
        <FormControlLabel
          control={
            <Switch
              checked={currentStatus}
              sx={{
                "& .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                  {
                    color: "#181D8C",
                  },
                "& .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                  {
                    backgroundColor: "#181D8C",
                  },
              }}
              onChange={handleChange}
            />
          }
          label=""
        />
        <span>{currentStatus ? "Active" : "Inactive"}</span>
      </>
    );
  };

  const fields: Field[] = [
    { label: "Company Name", type: "text" },
    { label: "Contact Number", type: "text" },
    { label: "Contact Email ID", type: "number" },
    { label: "Customer Full Name", type: "text" },
    { label: "GST Number", type: "text" },
    { label: "Address", type: "text" },
    { label: "City", type: "text" },
    { label: "State", type: "text" },
    { label: "Country", type: "text" },
    { label: "Pincode", type: "text" },
  ];
  const columns = [
    { field: "customer_no", headerName: "Customer ID", width: 150 },
    { field: "firstname", headerName: "Name", width: 170 },
    { field: "company_name", headerName: "Company Name", width: 200 },
    { field: "gst_no", headerName: "GST Number", width: 170 },
    { field: "email", headerName: "Contact Email ID", width: 200 },
    { field: "phone_number", headerName: "Contact Number", width: 200 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "city", headerName: "City", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    { field: "country", headerName: "Country", width: 200 },
    { field: "pincode", headerName: "Pincode", width: 200 },
    {
      field: "active_status",
      headerName: "Status",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (
        <StatusSwitch
          status={params.value === true || params.value === "true"}
          id={params.id}
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            marginLeft: "-68px",
          }}
        >
          <IconButton onClick={() => handleViewClick(params.row.id)}>
            <VisibilityIcon
              style={{
                width: "24px",
                height: "26px",
                color: "181D8C",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              handleDeleteClick(params.row.id, params.row.customer_no)
            }
          >
            <DeleteIcon
              style={{
                width: "24px",
                height: "24px",
                color: "181D8C",
                marginTop: "7px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setCustomerData({
      "Company Name": "",
      "Contact Number": "",
      "Contact Email ID": "",
      "Customer Full Name": "",
      "GST Number": "",
      Address: "",
      City: "",
      Pincode: "",
      State: "",
      Country: "",
    });
    setSwitchSelected(false);
    setUserSelected(false);
    setIsViewMode(false);
    setOpen(false);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "85vh",
        padding: "10px 20px 10px 20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.CUSTOMER}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
        <div style={{ marginRight: "-5px" }}>
          <PheonixButton
            style={{
              marginRight: "10px",
              color: "white",
              border: "1px solid #1C1B1F",
              backgroundColor: "#181D8C",
            }}
            onClick={handleOpen}
            label={TEXT_MESSAGES.NEW_CUSTOMER}
          ></PheonixButton>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "5px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredOrdersData.length > 0 ? filteredOrdersData : customerList
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
        />
      </div>
      <PheonixCustomerModal
        switchSelected={switchSelected}
        setSwitchSelected={setSwitchSelected}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
        open={open}
        view={viewMode}
        fields={fields}
        onClose={handleClose}
        formData={customerData}
        setFormData={setCustomerData}
        handleConfirm={handleConfirm}
      />
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={succMessage}
        vertical="top"
        horizontal="right"
      />

      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        entity={entityToDisplay}
        entityname="Customer"
      />
      <DeleteModal
        open={exitModalOpen}
        onClose={() => setexitModalOpen(false)}
        onConfirm={ExitConfirm}
        entity={entityToDisplay}
        entityname="Customer"
      />
    </div>
  );
};
export default Customer;
