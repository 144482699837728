import React, { useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme, IconButton } from "@mui/material";
import { useQuery } from "@apollo/client";
import { TEXT_MESSAGES } from "../../const";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import { CLIENT_PAYMENTS } from "../../graphql/query";
import { useNavigate } from "react-router-dom";
import PheonixDataGrid from "../../Components/PheonixDataGrid";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PaymentData from "./PaymentData";
const Payment: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [paymentsData, setPaymentsData] = useState<PaymentData[]>([]);
  const [filteredPaymentsData, setFilteredPaymentsData] = useState<
    PaymentData[]
  >([]);
  const [error, setError] = useState("");
  const userId = localStorage.getItem("userId");

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const {
    loading: paymentLoading,
    error: paymentError,
    data: paymentData,
    refetch: paymentRefetch,
  } = useQuery(CLIENT_PAYMENTS, {
    variables: {
      userId: userId,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const paymentsWithSerialNo = data.getAllPaymentsbyCustomerId.payments.map(
        (payment: any, index: number) => ({
          ...payment,
          serial_no: (
            paginationModel.page * paginationModel.pageSize +
            index +
            1
          ).toString(),
        })
      );
      const pagination = data.getAllPaymentsbyCustomerId.pagination;
      setPaymentsData(paymentsWithSerialNo);
      setTotalPages(pagination.total);
    },
  });

  useEffect(() => {
    paymentRefetch();
  }, []);

  useEffect(() => {
    if (
      !paymentLoading &&
      !paymentError &&
      paymentData?.getAllPaymentsbyCustomerId
    ) {
      const { payments, pagination } = paymentData.getAllPaymentsbyCustomerId;
      setPaymentsData(payments);
      setTotalPages(pagination.total);
    }
  }, [
    paymentData,
    paymentLoading,
    paymentError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  const getStatusColorAndIcon = (status: string) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return { color: "#05CD31", Icon: CheckCircleIcon };
      case "unpaid":
        return { color: "#9F9F9F", Icon: CancelIcon };
      case "partially paid":
        return { color: "#FC891F", Icon: CheckCircleIcon };
      case "cancelled":
        return { color: "#FD3232", Icon: CancelIcon };
      case "draft":
        return { color: "#181D8C", Icon: CheckCircleIcon };
      case "overdue":
        return { color: "#CD0505", Icon: ErrorOutlineOutlinedIcon };
      default:
        return { color: "#000000", Icon: CheckCircleIcon };
    }
  };

  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "₹";
      case "Dollars":
        return "$";
      case "Euro":
        return "€";
      default:
        return "₹";
    }
  };

  const columns = [
    { field: "serial_no", headerName: "S.No", width: 100 },
    { field: "invoicedate", headerName: "Invoice Date", width: 150 },
    {
      field: "invoice_id",
      headerName: "Invoice No",
      width: 150,
    },
    {
      field: "productDetails",
      headerName: "Product Details",
      width: 250,
      renderCell: (params: any) => {
        const productNames = params.row.productOrService
          .map((product: { name: string }) => product.name)
          .join(", ");
        return productNames || "NA";
      },
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 250,
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      width: 230,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol(params.row.currency);
        return params.value ? `${currencySymbol}${params.value}` : "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: string }) => {
        const status = params.value;
        const { color, Icon } = getStatusColorAndIcon(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },

    {
      field: "dueAmount",
      headerName: "Pending Amount",
      width: 160,
      renderCell: (params: any) => {
        const currencySymbol = getCurrencySymbol(params.row.currency);
        return params.value ? `${currencySymbol}${params.value}` : "NA";
      },
    },
  ];

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      paymentRefetch({ variables: { searchQuery: {} } });
    } else {
      paymentRefetch();
    }
  };
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "86vh",
        padding: "10px 20px 10px 20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.PAYMENT}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredPaymentsData.length > 0
              ? filteredPaymentsData
              : paymentsData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          searchQuery={searchQuery}
          onSearchQueryChange={handleSearchQueryChange}
          loading={paymentLoading}
        />
      </div>
    </div>
  );
};
export default Payment;
